import { faBicycle as faStarRegular } from "@fortawesome/free-solid-svg-icons/faBicycle"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { WindowLocation } from "@reach/router"
import React, { useEffect, useState } from "react"
import Layout from "../components/layout/layout"
import SearchLayoutBase from "../components/maps/searchLayoutBase"
import useIsLoggedIn from "../hooks/useisLoggedIn"
import useJwtGetClaim from "../hooks/useJwtGetClaim"
import { Kiosk } from "../interfaces/kiosk"

export default function Likes({ location }: { location: WindowLocation }) {
  const ls = typeof localStorage !== "undefined" ? localStorage : undefined
  const [kiosks, setKiosks] = useState<Kiosk[]>([])

  const isloggedIn = useIsLoggedIn()

  const likes = useJwtGetClaim("likes") ?? ls?.getItem("likes")
  useEffect(() => {
    if (!isloggedIn || !likes) {
      return
    }

    const items = likes?.split(";") ?? []
    if (items.length > 0) {
      import("./../utility/search")
        .then(x => x.searchByIds(items))
        .then(x => {
          setKiosks(x)
        })
    }
  }, [isloggedIn, likes])

  // if (!isloggedIn) {
  //   return <Login location={location} noRedirect />
  // }

  if (kiosks.length === 0) {
    return (
      <Layout title="Instagram" location={location}>
        <div className="page" style={{ textAlign: "center" }}>
          Nog geen kraampjes geliked. Vind kraampjes gemakkelijk terug door op
          het <FontAwesomeIcon icon={faStarRegular} size="sm" title="Likes" />{" "}
          icoon te klikken.
        </div>
      </Layout>
    )
  }

  return (
    <Layout title="Likes" location={location}>
      <SearchLayoutBase
        kiosks={kiosks}
        pageContext={{ zoom: 13, tags: [], places: undefined }}
        doNotSearchOnInit
        noActiveUpdate
        selectionText="Kraampjes op de route"
      />
    </Layout>
  )
}
