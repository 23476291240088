import { useContext } from "react";
import { useJwt } from "react-jwt";
import UserContext from "../context/userContext";


export default function useJwtGetClaim(claim: string): string {
  const { state } = useContext(UserContext);
  const jwt = useJwt(state?.jwt ?? "");

  return !jwt.isExpired && jwt.decodedToken
    && Object.entries(jwt.decodedToken).find(([key]) => (key === claim))?.[1];

}
